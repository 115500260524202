/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-01-12 16:04:56
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-03-18 16:16:38
 */
import request from '@/request/request'
// 主页上
export function content(data) {
    return request({
        url:'/Property/Home/CornerMark',
        method:'post',
        data
    })
}
// 下方列表
export function Task(data) {
    return request({
        url:'/Property/Home/UnfinishedTask',
        method:'post',
        data
    })
}