<template>
  <div class="flex-col page">
    <div class="flex-col group_1">
      <div class="flex-col section_2">
        <div class="flex-row">
          <div class="flex-row group_5">
            <img src="../../assets/xfang.png" class="image_3" />
            <span class="text_1">大连瀚云网络维保有限公司</span>
          </div>
          <span class="text_2">{{city}} 晴 {{wendu}}</span>
        </div>
          <div  class="carl"> 
            <Carousel/>
          </div>

      </div>
      <div class="flex-col group_17">
        <!-- 物业端 -->
        <div class="flex-row equal-division">
          <div class="flex-col equal-division-item">
            <div class="flex-col items-center group_14" @click="skipElevatorList()">
              <img src="../../assets/icon6.png" class="image_14" />
              <!-- 角标 -->
              <div class="flex-col items-center badge yuandian" v-show="elevatorCount>0">
                {{elevatorCount}}
              </div>
            </div>
            <span class="text_10">电梯管理</span>
          </div>
          <div class="flex-col items-center equal-division-item_1">
            <div class="flex-col items-center group_14">
              <img src="../../assets/icon8.png" class="image_17"/>
            </div>
            <span class="text_11">内禁管理</span>
          </div>
          <div class="equal-division-item_2 flex-col items-center">
            <!-- 角标 -->
            <div class="flex-col items-center group_14">
              <img src="../../assets/icon9.png" class="image_14"/>
            </div>
            <span class="text_12">物业管理</span>
          </div>
          <div class="equal-division-item_2 flex-col items-center">
            <div class="flex-col items-center group_14">
              <img src="../../assets/icon10.png" class="image_14"/>
            </div>
            <span class="text_13">业主管理</span>
          </div>
        </div>
      <!-- --------------------------------------------------- -->
        <div class="flex-row group_18">
          <span
            class="text_15"
            @click="tab(index)"
            :class="{active : index===curId}"
            v-for="(item,index) in navTip"
            :key="item.id"
          >{{item.title}}</span>
        </div>
        <div class="flex-col group_19">
          <div class="flex-col list">
            <div class="list-item flex-col" :key="i" v-for="(item, i) in orderList"  @click="desk(item.id,item.order_type,item.state)">
              <div class="flex-row">
                <div class="left-text-wrapper flex-col items-center" >
                  <span v-show="item.order_type==='alarm'">报警</span>
                  <span v-show="item.order_type==='annual_inspection'">年检</span>
                  <span v-show="item.order_type==='maintenance'">维保</span>
                  <span v-show="item.order_type==='rescue'">救援</span>
                </div>
                <span class="text_17">{{item.number}}</span>
                <span class="text_19">{{item.address}}</span>
              </div>
              <span class="text_21">工单号：{{item.id}}</span>
              <span class="text_23">工单时间：{{item.time}}</span>
              <span class="text_25">类型：{{item.type}}</span>
              <span class="text_27">状态：{{item.StateName}}</span>
              <div class="text-wrapper flex-col items-center" >
                <span>{{item.StateName}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 
    <div class="justify-between tab-bar">
        <div class="flex-col items-center" @click="index()">
          <img src="../../assets/icon11.png" class="image_20" />
          <span>首页</span>
        </div>
        <div class="flex-col items-center" @click="step()">
          <img src="../../assets/stepicon.png" class="image_20" />
          <span>发布</span>
        </div>
        <div class="flex-col items-center" @click="my()">
          <img src="../../assets/icon12.png" class="image_20" />
          <span class="text_55">我的</span>
        </div>
    </div>
  </div>
</template>

<script>
import {content,Task} from'@/api/Property/Index/Index'
import Carousel from '@/components/Carousel.vue'
export default {
  data() {
    return {
      curId: 0,
      orderList: [],
      navTip: [
        { id: '1', title: '今日待办' },
        { id: '2', title: '近7日待办' }
      ],
      // 接口数据渲染
      elevatorCount:'',//电梯数量
      city:"",//大连
      wendu:"",//-3℃
      today:0,//可以用来判断下方的时间个数显示
      week:0,//同上一条
    }
  },
  components:{Carousel},
  mounted(){
    if(!localStorage.getItem('token')){
      this.$router.replace('/login')
    }else{
    // 初始上方数据请求
      content().then(res=>{
        let data = res.data.Data;
        this.city          = data.weather.city
        this.wendu         = res.data.Data.weather.wendu
        this.elevatorCount = data.elevatorCount

      })
      Task({time:'today'}).then(res=>{
        this.orderList=res.data.Data
      })
    }
  },
  methods: {
    tab(index) {
      this.curId = index
      if(index===0){
        Task({time:'today'}).then(res=>{
          this.orderList=res.data.Data
        })
      }else if(index===1){
        Task({time:'week'}).then(res=>{
          this.orderList=res.data.Data
        })
      }
    },
    skipElevatorList(){
      this.$router.push('/property/elevator/list/id')
    },
    index(){
      this.$router.push('/property/index')
    },
    my(){
      this.$router.push('/property/my')
    },
    step(){
      this.$router.push('/property/step')
    },
    desk(id,type,state){
      var url = 0;
      console.log(id)
      switch (state) {
        case 0:
          url = 'detail';   //待接单
          break;
        case 1:
          url = 'detail';   //待处理
          break;
        case 2:
          url = 'detail';   //处理中
          break;
        case 3:
          url = 'sign';  //已完成
          break;
      }
      this.$router.push('/property/' + type + '/' + url + '/' + id)
    },
  },
}
</script>

<style scoped>
@import '../../assets/common.css';
.image_20 {
  width: 1.69rem;
  height: 1.69rem;
}
.equal-division-item_2 {
  flex: 1 1 5.59rem;
  color: rgb(0, 0, 0);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  padding: 0.31rem 0 1.63rem;
  position: relative;
}
.image_14 {
  width: 3.5rem;
  height: 2.88rem;
}
.list-item {
  padding: 0.94rem 0.94rem 1rem;
  filter: drop-shadow(0px 0.063rem 0.75rem rgba(153, 153, 153, 0.1));
  background: #fff;
  border-radius: 0.6rem;
  position: relative;
}
.text_21 {
  margin-top: 1rem;
  color: rgb(153, 153, 153);
  font-size: 0.75rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.text_23 {
  margin-top: 0.94rem;
  color: rgb(153, 153, 153);
  font-size: 0.75rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.text_25 {
  margin-top: 0.94rem;
  color: rgb(153, 153, 153);
  font-size: 0.81rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.text_27 {
  margin-top: 0.94rem;
  color: rgb(153, 153, 153);
  font-size: 0.81rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.text-wrapper {
  padding: 0.28rem 0 0.46rem;
  color: rgb(0, 104, 255);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
  background: #f3f3f3 !important;
  border-radius: 0.7rem;
  width: 3.88rem;
  position: absolute;
  left: 16.19rem;
  top: 8.06rem;
}
.left-text-wrapper {
  padding: 0.25rem 0;
  color: rgb(255, 255, 255);
  font-size: 0.63rem;
  line-height: 0.63rem;
  white-space: nowrap;
  background-image: url('../../assets/icon2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 2.38rem;
  height: 1.13rem;
}
.text_17 {
  margin-left: 0.31rem;
  margin-top: 0.19rem;
  color: rgb(0, 0, 0);
  font-size: 0.94rem;
  line-height: 0.94rem;
  white-space: nowrap;
}
.text_19 {
  margin-left: 0.094rem;
  margin-top: 0.38rem;
  color: rgb(102, 102, 102);
  font-size: 0.81rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.image_5 {
  width: 1.31rem;
  height: 1.25rem;
}
.image_9 {
  width: 0.56rem;
  height: 0.88rem;
}
.page {
  background-color: rgb(250, 250, 250);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.header {
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
}
.group_1 {
  width: 100%;
  overflow: hidden;
}
.tab-bar {
  padding: 0.34rem 4.13rem 0.5rem 4.13rem;
  color: rgb(0, 0, 0);
  font-size: 0.63rem;
  line-height: 0.63rem;
  white-space: nowrap;
  filter: drop-shadow(0px 0px 0.94rem rgba(0, 0, 0, 0.1));
  background-image: url('../../assets/icon3.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 255);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.image {
  width: 100vw;
  height: 5.5vw;
}
.section_1 {
  padding: 0.88rem 0.81rem;
  background-color: rgb(237, 237, 237);
}
.group_17 {
  margin-top: -1.38rem;
  background: #fff;
  padding-top: 2rem;
  border-radius: 1rem 1rem 0 0;
}
.section_15 {
  align-self: center;
  width: 0.13rem;
  height: 1.31rem;
  border: solid 0.063rem rgb(57, 92, 154);
}
.image_2 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.equal-division {
  margin-top: -0.5rem;
  padding-bottom: 1.2rem;
}
.group_18 {
  padding: 0 1.31rem;
}
.group_19 {
  margin-top: 1.06rem;
  /* padding-bottom: 3.8rem; */
}
.text_55 {
  margin-top: 0.094rem;
}
.image_1 {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.69rem;
}
.section_2 {
  padding: 0.5rem 0.63rem 1.06rem;
  background: rgb(0, 83, 215) url(../../assets/imgbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.equal-division-item {
  padding: 0.5rem 0 1.31rem;
  flex: 1 1 5.59rem;
  position: relative;
}
.equal-division-item_1 {
  padding: 0.5rem 0 0.31rem;
  flex: 1 1 5.59rem;
  color: rgb(0, 0, 0);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.text_15 {
  margin-left: 0.88rem;
  margin-bottom: 0.13rem;
  color: rgb(153, 153, 153);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.active {
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
}
.list {
  margin: 0 1.25rem;
}
.group_6 {
  margin-top: 2.25rem;
  padding-left: 1rem;
  padding-right: 0.56rem;
}
.group_14 {
  padding-top: 0.13rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.63rem;
  white-space: nowrap;
  position: relative;
}
.text_10 {
  color: rgb(0, 0, 0);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  position: absolute;
  left: 1.19rem;
  top: 3.63rem;
}
.image_17 {
  filter: drop-shadow(0.25rem 0.19rem 0.44rem rgba(0, 104, 255, 0.16));
  width: 2.19rem;
  height: 2.19rem;
}
.text_11 {
  margin-top: 0.94rem;
}
.text_12 {
  position: absolute;
  left: 1.13rem;
  top: 3.63rem;
}
.text_13 {
  position: absolute;
  left: 1.09rem;
  top: 3.63rem;
}
.list-item:not(:first-of-type) {
  margin-top: 0.94rem;
}
.group_5 {
  flex: 1 1 auto;
  color: rgb(255, 255, 255);
  font-size: 0.94rem;
  line-height: 0.94rem;
  white-space: nowrap;
}
.text_2 {
  margin-left: 1.25rem;
  margin-right: 0.22rem;
  align-self: center;
  color: rgb(255, 255, 255);
  font-size: 0.81rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.image-wrapper {
  margin-top: 0.19rem;
  padding-top: 0.63rem;
  background-color: rgba(216, 216, 216, 0.01);
  width: 7.5rem;
  height: 9.38rem;
}
.badge {
  width: 1.19rem;
  position: absolute;
  left: 3.38rem;
  top: 0;
}
.image_3 {
  width: 1.69rem;
  height: 1.63rem;
}
.text_1 {
  margin-left: 0.63rem;
  align-self: center;
}
.image_4 {
  width: 6.63rem;
  height: 8.75rem;
}
.group_8 {
  padding: 4rem 0.063rem 0.81rem 0.44rem;
}
.group_11 {
  padding: 1.06rem 0.063rem 0 0.44rem;
  border-top: solid 0.19rem rgb(204, 204, 204);
}
.image_16 {
  width: 1.13rem;
  height: 1.13rem;
}
.text_9 {
  position: absolute;
  left: 0.23rem;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 0.35rem;
}
.image_10 {
  margin-left: 0.5rem;
  margin-top: 0.1rem;
}
.text_5 {
  margin-left: 0.69rem;
  margin-top: 0.19rem;
  color: rgb(255, 255, 255);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.group_12 {
  margin-left: 1.8rem;
  margin-top: 0.19rem;
}
.text_3 {
  color: rgb(255, 255, 255);
  font-size: 0.88rem;
  margin-left: 0.69rem;
}
.text_4 {
  color: rgb(232, 196, 54);
  font-size: 0.88rem;
  font-weight: 500;
  margin-left: 3rem;
}
.group_13 {
  line-height: 0.75rem;
  white-space: nowrap;
  height: 0.75rem;
}
.image_12 {
  margin-left: 0.5rem;
  margin-top: 0.1rem;
}
.text_6 {
  color: rgb(232, 196, 54);
  font-size: 0.88rem;
  line-height: 0.69rem;
}
.text_7 {
  color: rgb(255, 255, 255);
  font-size: 0.88rem;
  line-height: 0.75rem;
}
.text_8 {
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.63rem;
}
.red-left{
  left: 1.8rem;
}
.yuandian{
  text-align: center;
  height: 1.19rem;
  line-height: 1.19rem;
  background-image:url(../../assets/icon7.png);
  background-repeat: no-repeat;
}
.carl{
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>